<template>
  <section>
    <div>
      <template v-if="!fromQuickAction">
        <span v-if="isActive" class="setting-icon" @click="openSettings">
        <i class="el-icon-s-tools" />
      </span>
      <el-link
        :loading="loading"
        v-if="data && data.action_button_type == 'HYPERLINK'"
        :style="hideStyles ? getGalleryStyles : getStyle"
        @click="action"
        :disabled="checkReadonly"
        :underline="false"
      >
        {{ label||data.label }}
      </el-link>
      <el-button
        :loading="loading"
        :style="hideStyles ? '' : getStyle"
        :round="isRound"
        v-else
        @click="action"
        :disabled="checkReadonly"
      >
        {{label||data.label }}
      </el-button>
      </template>
      <dialog-component
        :title="viewDataOnly ? 'Related '+(relationshipDetails.relationship_title || 'data') : allowNewAdd ? 'Add or assign' : 'Assign'"
        :visible="openRelationalModal"
        @before-close="resetAddModal"
        :containerMaxHeight="'90%'"
      >
        <!-- <el-scrollbar wrap-style="max-height: 1000px; padding-right: 10px;"> -->
        <quick-entity-filter
          v-if="relationshipDetails && openRelationalModal"
          :entityId="relationshipDetails.relationship_id"
          :relationshipDetails="relationshipDetails"
          :allowNewAdd="allowNewAdd"
          :actionButtonField="data"
          :viewDataOnly="viewDataOnly"
          :allowNewAddOnly="allowNewAddOnly"
          @quickAddClose="resetAddModal"
          @entityDataSelect="updateEntityData"
        >
        </quick-entity-filter>
        <!-- </el-scrollbar> -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="resetAddModal">{{viewDataOnly ? 'Close' : 'Cancel'}}</el-button>
          <el-button
            v-if="!viewDataOnly"
            type="primary"
            @click="establichRelationship"
            :disabled="checkDisabled"
            :loading="loading"
            >Save</el-button
          >
        </span>
      </dialog-component>
    </div>
  </section>
</template>

<script>
import templateBuilderHelper from "@/mixins/templateBuilderHelper";
import { mapGetters } from "vuex";
import appConfig from "@/config/app";
import { makeSlug } from "../../../helpers/appHelper";
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
export default {
  name: "templates-formComponentsExecute-ActionButtonExecute",
  props: [
    "data",
    "label",
    "form",
    "isActive",
    "workflowDataId",
    "entityId",
    "entityDataId",
    "fieldsData",
    "parentFields",
    "checkIsDisabled",
    "hideStyles",
    "isView",
    "selectedTableRows",
    "fromQuickAction"
  ],
  mixins: [templateBuilderHelper, userPermissionsHelper],
  components: {
    QuickEntityFilter: () => import("../../widgets/EntityAddSelectWidget.vue"),
  },
  computed: {
    ...mapGetters("formBuilders", ["getPublicformBuilderDetails"]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getEntityAllRelationshipData",
      "getEntityRelationshipDataAssignStatus",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveContactType", "getAuthenticationStatus"]),
    ...mapGetters("templatesData", [
    "getTemplateDeleteStatus",
    "getTemplateDeleteError",
    "getDownloadUrl",
    "getDownloadError",
    "getDuplicateEntityData",
    "getDuplicateSelectedEntityData"
  ]),
    getButtonColor() {
      if (this.data?.styles?.label_color) {
        return this.data.styles.label_color;
      }
      return "black";
    },
    getBackgroundColor() {
      if (this.data?.styles?.input_background) {
        return this.data.styles.input_background;
      }
      return "#285ED3";
    },
    getGalleryStyles() {
      return "font-size: 12px; color: #409EFF;";
    },
    getStyle() {
      if (this.hideStyles) {
        return "";
      }
      let style = `color:${this.getButtonColor};`;
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        if (this.data?.styles?.font?.name) {
          borderStyle += "font-family:" + this.data.styles.font.name + ";";
        }
      }
      if (this.data?.action_button_type != "HYPERLINK") {
        style += `background-color: ${this.getBackgroundColor} !important;`;
      }
      style += borderStyle;
      return style;
    },

    isRound() {
      if (this.data?.styles?.botton_type == "Rounded") {
        return true;
      }
      return false;
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      return false;
    },
    checkDisabled() {
      return this.selectedChildEntitiesData &&
        this.selectedChildEntitiesData.length
        ? false
        : true;
    },
  },
  data() {
    return {
      validations: [],
      showRepeatingTemplate: false,
      tableData: [],
      selectedTemplateFields: [],
      pageLoading: false,
      entitiesData: [],
      actionButtons: [],
      loading: false,
      tableHeaders: [],
      repeatableTemplate: {},
      openModal: false,
      modalData: null,
      populatedEntitiesData: [],
      isExecute: false,
      inLineForm: {},
      editIndex: -1,
      entitie: [],
      entityFields: [],
      hasLabel: false,
      otherActionUrl: "",
      allowedRelationships: [],
      actions: [],
      navigationAction: null,
      userEntityDataId: "",
      activeContactType: "",
      openRelationalModal: false,
      relationshipsData: [],
      relationshipDetails: {},
      selectedChildEntitiesData: [],
      existedRelationshipsMap: [],
      authenticationStatus: false,
      allowNewAddOnly: false,
      allowNewAdd: false,
      viewDataOnly: false,
      unAssignedFieldDetails: []
    };
  },
  mounted() {
    if (this.getActiveContactType?.contact_type?._id) {
      this.activeContactType = this.getActiveContactType?.contact_type?._id;
      this.userEntityDataId = this.getActiveContactType.account_data_id;
    }
    let checkNavigation = (this.data.actions || []).find(
      (e) => e.action_button_action_type == "NAVIGATION"
    );
    if (this.data.parent_entityId && this.data.parent_entityDataId) {
      this.checkEntityRelationships(
        this.data.parent_entityId,
        this.data.parent_entityDataId
      );
    }
    if (checkNavigation) {
      this.navigationAction = checkNavigation;
      if (
        checkNavigation?.action_button_action_navigation_type ==
        "OPEN_FORM_BUILDER"
      ) {
        this.fetchFormbuilder(checkNavigation.action_button_formbuilder_id);
      } else if (
        checkNavigation?.action_button_action_navigation_type ==
        "OPEN_DOCUMENT_TEMPLATE_BUILDER"
      ) {
        this.fetchDocumentTemplate(
          checkNavigation.action_button_document_template_id
        );
      }
    }
    //remove navigation action
    this.actions = (this.data.actions || []).filter(
      (e) => e.action_button_action_type != "NAVIGATION"
    );
  },
  methods: {
    async updateRelationships() {
      let relationEntity = this.actions.find(
        (e) =>
          e.action_button_action_type == "ESTABLISH_RELATIONSHIP" &&
          e.relational_entity_one
      );
      if (
        this.activeContactType == relationEntity?.relational_entity_one &&
        this.userEntityDataId
      ) {
        this.selectedChildEntitiesData = [this.userEntityDataId];
        this.establichRelationship();
      } else if (this.relationshipDetails) {
        if(relationEntity.action_button_relationship_type == 3){
          this.allowNewAdd = true;
        }
        if(relationEntity.action_button_relationship_type == 2){
          this.allowNewAddOnly = true;
          this.allowNewAdd = true;
        }
        if(relationEntity.action_button_relationship_type == 4){
          this.viewDataOnly = true;
          this.allowNewAddOnly = false;
          this.allowNewAdd = false;
        }
        this.openRelationalModal = true;
      } else {
        if (this.navigationAction) {
          this.checkNavigationAction(this.navigationAction);
        }
      }
    },
    async establichRelationship() {
      this.loading = true;
      let data;
      if (this.relationshipDetails.owner_type == "PARENT") {
        data = {
          parent_entity_id: this.relationshipDetails.parent_entity_id,
          child_entity_id: this.relationshipDetails.child_entity_id,
          parent_entity_data_id: this.relationshipDetails.parent_entity_data_id,
          child_entity_data_ids: this.selectedChildEntitiesData,
        };
      } else {
        if (this.relationshipDetails.parent_relation_type == "MANY") {
          data = {
            parent_entity_id: this.relationshipDetails.child_entity_id,
            child_entity_id: this.relationshipDetails.parent_entity_id,
            parent_entity_data_id:
              this.relationshipDetails.parent_entity_data_id,
            child_entity_data_ids: this.selectedChildEntitiesData,
          };
        } else {
          data = {
            parent_entity_id: this.relationshipDetails.parent_entity_id,
            child_entity_id: this.relationshipDetails.child_entity_id,
            parent_entity_data_id: this.selectedChildEntitiesData[0],
            child_entity_data_ids: [
              this.relationshipDetails.parent_entity_data_id,
            ],
          };
        }
        if (
            this.relationshipDetails.representation == "FIELD" &&
            this.unAssignedFieldDetails &&
            this.unAssignedFieldDetails.length
          ) {
            data.assign_field_data = [];
            this.selectedChildEntitiesData.forEach((entity_data_id) => {
              this.unAssignedFieldDetails.forEach((field) => {
                data.assign_field_data.push({
                  ...field,
                  entity_data_id,
                });
              });
            });
          }
      }
      await this.$store.dispatch(
        "entityRelationships/assignEntityRelationshipData",
        data
      );

      if (this.getEntityRelationshipDataAssignStatus) {
        this.loading = false;
        this.$message.success(
          `${this.relationshipDetails.relationship_title} data added successfully`
        );
        this.resetAddModal();
        if (this.navigationAction) {
          this.checkNavigationAction(this.navigationAction);
        }
      } else {
        this.loading = false;
      }
    },
    updateEntityData(data) {
      if (data?.value) {
        if (typeof data.value == "object" && data.value.length) {
          this.selectedChildEntitiesData = [...data.value];
        } else {
          this.selectedChildEntitiesData = [data.value];
        }
      }
      if(data.unAssignedFieldDetails){
        this.unAssignedFieldDetails = data.unAssignedFieldDetails;
      }
      if(data.update){
        this.establichRelationship();
      }
    },
    resetAddModal() {
      this.selectedChildEntitiesData = [];
      this.openRelationalModal = false;
      this.$emit('closeModal')
    },
    async getExistedRelationshipsData(entityId, entityDataId) {
      await this.$store.dispatch(
        "entityRelationships/fetchEntityAllRelationshipData",
        {
          parent_entity_id: entityId,
          parent_entity_data_id: entityDataId,
        }
      );
      if (this.getEntityAllRelationshipData) {
        this.setEntityRelationshipMapData(this.getEntityAllRelationshipData, this.relationshipDetails.relationship_id)
      }
    },
    setEntityRelationshipMapData(reationshipData, entityId) {
      this.existedRelationshipsMap = [];
      (reationshipData || []).forEach((relation) => {
        if (relation?.relationship_id == entityId && relation?.entity_data_ids) {
          this.existedRelationshipsMap = [
            ...this.existedRelationshipsMap,
            ...relation.entity_data_ids.filter((e) => e),
          ];
        }
      });
    },
    async checkEntityRelationships(entityId, entityDataId) {
      this.allowedRelationships = [];
      await Promise.all([
        this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
          entity_id: entityId,
        }),
      ]);
      if (this.getEntityRelationships) {
        this.setRelationshipDetails(entityId, entityDataId);
      }
    },
    setRelationshipDetails(entityId, entityDataId) {
      this.relationshipsData = [...(this.getEntityRelationships || [])];
      // we have to add type owner of relationship
      this.relationshipsData = this.relationshipsData.map((e) => {
        if (e.parent_entity_id == entityId) {
          e.owner_type = "PARENT";
          e.relationship_title = e.child_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.child_entity_id;
        } else {
          e.owner_type = "CHILD";
          e.relationship_title = e.parent_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.parent_entity_id;
        }
        e.parent_entity_data_id = entityDataId;
        return e;
      });

      this.relationshipsData = this.relationshipsData.filter((e) => {
        if (e.owner_type == "CHILD" && !e.is_bidirectional) {
          return false;
        } else {
          return true;
        }
      });
      this.relationshipsData = this.relationshipsData.filter(
        (e) => e.owner_type == "CHILD" || e.representation == "TAB"
      );
      let relationEntity = this.actions.find(
        (e) =>
          e.action_button_action_type == "ESTABLISH_RELATIONSHIP" &&
          e.relational_entity_one
      );
      if (relationEntity) {
        this.relationshipDetails = this.relationshipsData.find(
          (e) => e.relationship_id == relationEntity.relational_entity_one
        );
        if (this.relationshipDetails) {
          this.setAllowOne();
          this.getExistedRelationshipsData(entityId, entityDataId)
        }
        if(this.fromQuickAction){
          this.action()
        }
      }
    },
    setAllowOne() {
      this.relationshipDetails.allowOne = null;

      if (this.relationshipDetails.representation == "TAB") {
        if (
          (this.relationshipDetails.owner_type == "CHILD" &&
            this.relationshipDetails.parent_relation_type == "ONE") ||
          (this.relationshipDetails.owner_type == "PARENT" &&
            this.relationshipDetails.child_relation_type == "ONE")
        ) {
          this.relationshipDetails.allowOne = true;
        } else {
          this.relationshipDetails.allowOne = false;
        }
      } else {
        if (this.relationshipDetails.parent_relation_type == "MANY") {
          this.relationshipDetails.allowOne = false;
        } else {
          this.relationshipDetails.allowOne = true;
        }
      }
    },
    async fetchDocumentTemplate(template_id) {
      if (template_id && template_id.includes("#")) {
        this.otherActionUrl =
          appConfig.APP_URL +
          `/company-documents/${template_id.split("#")[0]}/configure/template/${
            template_id.split("#")[1]
          }/preview`;
      }
    },
    async fetchFormbuilder(formbuilder_id) {
      this.loading = true;
      this.otherActionUrl = "";
      await this.$store.dispatch("formBuilders/fetchPublicWorkFlowById", {
        id: formbuilder_id,
      });
      if (this.getPublicformBuilderDetails?._id) {
        this.loading = false;
        this.otherActionUrl =
          appConfig.APP_URL +
          "/public/fb" +
          "/" +
          `${this.getPublicformBuilderDetails.code}` +
          "/" +
          `${this.getPublicformBuilderDetails.steps[0].slug}` +
          "/" +
          `${this.getPublicformBuilderDetails._id}` +
          "/auth/verify";

        if (this.getAuthenticatedUser?.is_contact) {
          this.otherActionUrl = "";

          this.otherActionUrl =
            appConfig.APP_URL +
            "/public/fb" +
            "/" +
            `${this.getPublicformBuilderDetails.code}` +
            "/" +
            `${this.getPublicformBuilderDetails.steps[0].slug}` +
            "/" +
            `${this.getPublicformBuilderDetails._id}` +
            "/vp/" +
            this.getAuthenticatedUser._id;
          const queryParams = {
            application_user: "true",
          };

          // Convert the query parameters object to a query string
          const queryString = Object.keys(queryParams)
            .map(
              (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(
                  queryParams[key]
                )}`
            )
            .join("&");
          this.otherActionUrl = `${this.otherActionUrl}?${queryString}`;
        }
      } else {
        this.loading = false;
      }
    },
    getValidUrl(url) {
      if (!url.includes("http://") && !url.includes("https://"))
        return "https://" + url;
      return url;
    },
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    isValidURL(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    openURL(url, action) {
      switch (action.action_button_target_location) {
        case "SELF":
          window.location.href = url;
          break;
        case "NEW_TAB":
          window.open(url, "_blank");
          break;
        case "NEW_WINDOW":
          window.open(url, "MsgWindow", "width=800,height=800");
          break;
        default:
          window.location.href = url;
          break;
      }
    },
    applyFormRules() {
      this.$set(this.form, this.data.key, true);
      this.$emit("applyFormRules");
      let relationAction = (this.actions || []).find(
        (action) => "ESTABLISH_RELATIONSHIP" == action.action_button_action_type
      );
      if (this.navigationAction && !relationAction) {
        this.checkNavigationAction(this.navigationAction);
      }
      // setTimeout(() => {
      //   this.$set(this.form, this.data.key, false);
      // }, 500);
    },
    checkNavigationAction(action) {
      switch (action.action_button_action_navigation_type) {
        case "OPEN_URL":
          if (action.action_button_target_link) {
            if (
              this.isValidURL(action.action_button_target_link) &&
              this.isValidHttpUrl(
                this.getValidUrl(action.action_button_target_link)
              )
            ) {
              let url = this.getValidUrl(action.action_button_target_link);
              this.openURL(url, action);
            } else {
              this.$message.info("Invalid URL");
            }
          } else {
            this.$message.info("No Hyperlink to open");
          }
          break;
        case "OPEN_FORM_BUILDER":
          if (action.action_button_formbuilder_id && this.otherActionUrl) {
            this.openURL(this.otherActionUrl, action);
          } else {
            this.$message.info("No form builder selected");
          }
          break;
        case "OPEN_DOCUMENT_TEMPLATE_BUILDER":
          if (
            action.action_button_document_template_id &&
            this.otherActionUrl
          ) {
            this.openURL(this.otherActionUrl, action);
          } else {
            this.$message.info("No document template selected");
          }
          break;
      }
    },
    async action() {
      if (this.actions && this.actions.length) {
        await (this.actions || []).forEach(async (action) => {
          switch (action.action_button_action_type) {
            case "TEMPLATE_ACTION":
              await this.applyFormRules();
              break;
            case "ESTABLISH_RELATIONSHIP":
              await this.updateRelationships();
              break;
            case "CREATE_DUPLICATE":
              await this.createDuplicateData(action);
              break;
          }
        });
      } else {
        if (this.navigationAction) {
          this.checkNavigationAction(this.navigationAction);
        }
      }
    },
    async createDuplicateData(action) {
      if(this.isView) {
        return this.$message.warning("can't perform duplicate data action in view mode");
      } else if(!this.$route?.query?.dataId) {
        return this.$message.warning("can't perform this action while adding new data");
      }
      let fieldsDataObject = {}
      this.fieldsData.map(e => {
        fieldsDataObject[`${e.template_id}#${e.key}`] = e;
      })
      let data = {
        entity_id: this.entityId,
        existed_data_id: this.entityDataId,
        current_date: this.getCurrentDate,
        selected_fields : action?.selected_fields || [],
        data_table_selected_rows : this.selectedTableRows,
        current_template_id : this.fieldsData?.[0]?.template_id
      };
      let isValid = true;
      action.selected_fields.map(e => {
        let field = fieldsDataObject[e];
        if((field.input_type == 'DATA_TABLE' || 
            field.inputType == 'DATA_TABLE') &&
            !data.data_table_selected_rows[e]?.length
          ) {
          isValid = false;
          this.$message.warning(`Please select ${field.label} rows`);
        }
      })
      if(isValid) {
        this.$emit("loadComponent", {value : true , loadingText : "Creating New Data...."});
        await this.$store.dispatch("templatesData/duplicateSelectedEntityData", data);
        if (this.getDuplicateSelectedEntityData?._id) {
          this.$emit("loadComponent",{value : false, loadingText : ""});
          this.setSelectedTableRows()
          this.$notify.success({
            title: "Success",
            message: "Data Created successfully",
          });
          await this.applyFormRules();
        } else {
          this.previewComponentLoading = false;
          this.$notify.error({
            title: "Error",
            message:
              "Error while duplicating " +
              (this.currentEntity && this.currentEntity.name
                ? this.currentEntity.name
                : "data"),
          });
        }
      }
    },
    setSelectedTableRows() {
      Object.keys(this.selectedTableRows).map(key => this.selectedTableRows[key] = []);
    }
  },
};
</script>

<style lang="scss">
.heading {
  margin: 3px 0px 3px 0px !important;
  line-height: 20px;
}
</style>
